export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_AJAX_START = 'AUTH_AJAX_START';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PASSWORD_UPDATED = 'AUTH_PASSWORD_UPDATED';
export const AUTH_PASSWORD_RECOVERY_SENT = 'AUTH_PASSWORD_RECOVERY_SENT';
export const AUTH_AJAX_FAIL = 'AUTH_AJAX_FAIL';
export const AUTH_TWO_FACTOR_AUTH_CODE_RECEIVED = 'AUTH_TWO_FACTOR_AUTH_CODE_RECEIVED';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
