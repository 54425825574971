export const PUSHER = {
  id: '2af9c86620e471cb9837',
  cluster: 'eu',
  encrypted: true,
  broadcast: 'monevert',
  channels: {
    agent: {
      cases: 'agent-case'
    },
    system: {
      status: 'heartbeat'
    }
  }
};

export const REGEX_ONLY_DIGITS = 'regex_only_digits';
