import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils';

const initialState = {
  checked: false,
  token: null,
  userId: null,
  username: null,
  role: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  recovery: false,
  email: null,
  name: null,
  twoFactorToken: null
};

const authStart = (state, action) => updateObject(state, {
  loading: true,
  error: null
});

const ajaxLoading = (state, action) => updateObject(state, {
  loading: true,
  error: null
});

const authSuccess = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  token: action.token,
  userId: action.userId,
  role: action.role,
  name: action.name,
  email: null,
  recovery: false,
  checked: true
});

const passwordRecoveryRequested = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  recovery: true,
  email: action.email
});

const passwordUpdated = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  token: action.token
});

const authFail = (state, action) => updateObject(state, {
  loading: false,
  error: action.error,
  userId: null,
  token: null,
  checked: true
});

const ajaxFail = (state, action) => updateObject(state, {
  loading: false,
  error: action.error,
  userId: null,
  token: null,
  checked: true
});

const authLogout = (state, action) => updateObject(state, initialState);

const setAuthRedirectPath = (state, action) => updateObject(state, {
  authRedirectPath: action.path
});

const authTwoFactorTokenReceived = (state, action) => updateObject(state, {
  loading: false,
  twoFactorToken: action.code
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_AJAX_FAIL: return ajaxFail(state, action);
    case actionTypes.AUTH_AJAX_START: return ajaxLoading(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_PASSWORD_UPDATED: return passwordUpdated(state, action);
    case actionTypes.AUTH_PASSWORD_RECOVERY_SENT: return passwordRecoveryRequested(state, action);
    case actionTypes.AUTH_TWO_FACTOR_AUTH_CODE_RECEIVED: return authTwoFactorTokenReceived(state, action);
    default: return state;
  }
};

export default reducer;
