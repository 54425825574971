import React, { Component } from 'react';
import {
  Route, Switch, Redirect, withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import * as actions from './store/actions';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  componentDidMount () {
    this.props.onTryAutoSignup();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    ReactGA.initialize('UA-186406837-1');
  }

  // componentDidUpdate () {
  //   if (this.props.userId) {
  //     ReactGA.set({ userId: this.props.userId });
  //   }
  // }

  render () {
    let redirect = null;

    ReactGA.pageview(window.location.pathname + window.location.search);

    if (this.props.isAuthChecked && !this.props.isAuth) {
      redirect = <Redirect to="/login" />;
    }

    return (
      <React.Suspense fallback={loading()}>
        {redirect}
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    );
  }
}

const mapStateToProps = state => ({
  isAuthChecked: state.auth.checked,
  isAuth: state.auth.token !== null,
  role: state.auth.role
});

const mapDispatchToProps = dispatch => ({
  onTryAutoSignup: () => dispatch(actions.authCheckState())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
