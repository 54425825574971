import { REGEX_ONLY_DIGITS } from './parameters';

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties
});

export const time = () => new Date();

export const isInt = value => !isNaN(value) &&
         parseInt(Number(value), 10) === value &&
         !isNaN(parseInt(value, 10));

export const hasNumber = check => /\d/.test(check);

export const validateControlsForm = (controls) => {
  const updatedControls = {
    ...controls
  };
  for (const formElementIdentifier in controls) {
    updatedControls[formElementIdentifier] = {
      ...controls[formElementIdentifier],
      touched: true
    };
  }

  return updatedControls;
};

export const formInputChangedHandler = (controls, inputIdentifier, newValue) => {
  const updatedControl = {...controls};
  const updatedField = {...updatedControl[inputIdentifier]};
  if (controls && controls.regex && controls.regex.some(e => e === REGEX_ONLY_DIGITS)) {
    newValue = newValue.replace(/[^0-9]+/g, '');
  }
  updatedField.value = newValue;
  updatedField.valid = checkValidity(newValue, updatedField.validation);
  updatedField.touched = true;
  updatedControl[inputIdentifier] = updatedField;
  let formIsValid = true;
  for (const field in updatedControl) {
    if (field !== 'regex') {
      formIsValid = updatedControl[field].valid && formIsValid;
    }
  }
  return { controls: updatedControl, formIsValid };
};

export const isFormValid = (controls) => {
  let formIsValid = true;
  for (const identifier in controls) {
    formIsValid = controls[identifier].valid && formIsValid;
  }
  return formIsValid;
};

export const buildFormData = (controls) => {
  const formData = {};
  for (const formElementIdentifier in controls) {
    formData[formElementIdentifier] = controls[formElementIdentifier].value;
  }
  return formData;
};

export const hasAlphaNumeric = check => /^(?!-)(?!.*-)[A-Za-z0-9-]+(?<!-)$/.test(check);

export const hasAlphabet = check => /[a-zA-Z]/.test(check);

export const hasUpperLetter = check => /[A-Z]/.test(check);

export const hasLowerLetter = check => /[a-z]/.test(check);

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }
  if (rules.required) {
    if (!isInt(value)) {
      isValid = value.trim() !== '' && isValid;
    }
  } else if (!rules.required && rules.required === false && value.trim() === '') {
    return true;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }
  if (rules.minValue) {
    isValid = parseInt(value, 10) >= parseInt(rules.minValue, 10) && isValid;
  }
  if (rules.notEmpty) {
    isValid = value !== '' && isValid;
  }
  if (rules.maxLength && value.trim() !== '') {
    isValid = value.length <= rules.maxLength && isValid;
  }
  if (rules.domain) {
    isValid = (value.startsWith('http://') || value.startsWith('https://')) && isValid;
  }
  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.isNumeric) {
    // https://regexr.com/ // a specialised website to test and troubleshoot RegEx formulas
    // const pattern = /^\d+$/; // the old no comma pattern
    const pattern = /^[0-9]+([.][0-9]+)?$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.containsDigits) {
    isValid = hasNumber(value) && isValid;
  }
  if (rules.containsAlphabet) {
    isValid = hasAlphabet(value) && isValid;
  }
  if (rules.containsUpperLetter) {
    isValid = hasUpperLetter(value) && isValid;
  }
  if (rules.containsLowerLetter) {
    isValid = hasLowerLetter(value) && isValid;
  }
  return isValid;
};

export const serializeQuery = (params, prefix) => {
  const query = Object.keys(params)?.map((key) => {
    const value = params[key];

    if (params.constructor === Array) {
      key = `${prefix}[]`;
    } else if (params.constructor === Object) {
      key = (prefix ? `${prefix}[${key}]` : key);
    }

    if (typeof value === 'object') {
      return serializeQuery(value, key);
    }
    return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join('&');
};

export const containsObject = (obj, list) => {
  let i;
  for (i = 0; i < list.length; i += 1) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
};

export const leftPad = (number, targetLength = 6) => {
  let output = `${number}`;
  while (output.length < targetLength) {
    output = `0${output}`;
  }
  return output;
};

export const isArray = value => value && typeof value === 'object' && value.constructor === Array;

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const dateToString = (d) => {
  let month = parseInt(d.getUTCMonth() + 1, 10);
  if (month < 10) {
    month = `0${month}`;
  }
  return `${d.getUTCFullYear()}-${month}-${(`0${d.getUTCDate()}`).slice(-2)}`;
};

export const now = () => new Date();

export const numberToMonth = (index) => {
  const month = [];
  month[0] = 'January';
  month[1] = 'February';
  month[2] = 'March';
  month[3] = 'April';
  month[4] = 'May';
  month[5] = 'June';
  month[6] = 'July';
  month[7] = 'August';
  month[8] = 'September';
  month[9] = 'October';
  month[10] = 'November';
  month[11] = 'December';

  return month[index];
};

export const hotColor = (perc) => {
  const base = 100;

  const percent = base === 0 ? 100 : (perc - 0) / base * 100;

  let r;
  let g;
  const b = 0;
  if (percent < 50) {
    r = Math.round(5.1 * percent);
    g = 255;
  } else {
    g = Math.round(510 - 5.10 * percent);
    r = 255;
  }
  const h = r * 0x10000 + g * 0x100 + b * 0x1;
  return `#${(`000000${h.toString(16)}`).slice(-6)}`;
};

export const parseValidationError = (validationErrors) => {
  const errors = [];
  for (const [field, error] of Object.entries(validationErrors)) {
    errors.push(`${field.replace('_', ' ')} - ${error}`);
  }
  if (errors.length > 0) {
    return errors;
  }
  return errors.push('The given data was invalid.');
};

export const translateOperation = (operation) => {
  if (operation === '<') {
    return 'Less Than';
  } if (operation === '<=') {
    return 'Less Than or Equal';
  } if (operation === '>') {
    return 'Greater Than';
  } if (operation === '>=') {
    return 'Greater Than or Equal';
  } if (operation === '=') {
    return 'Equal';
  } if (operation === '!=') {
    return 'Not Equal';
  } if (operation === 'in') {
    return 'In';
  } if (operation === 'not in') {
    return 'Not In';
  }
  return operation;
};

export const translateField = (field) => {
  if (field === 'countryCode') {
    return 'Country Code';
  } if (field === 'site') {
    return 'Site Code';
  } if (field === 'cartStatus') {
    return 'Status';
  } if (field === 'base_totalET') {
    return 'Total ex. tax';
  } if (field === 'base_totalATI') {
    return 'Total inc. tax';
  } if (field === 'locale') {
    return 'Locale';
  } if (field === 'payment_method') {
    return 'Payment Method';
  } if (field === 'countryCode') {
    return 'Country Code';
  } if (field === 'sale_at') {
    return 'Sale At';
  }
  return field;
};

export const searchSidebarStyles = {
  sidebar: {
    background: 'white',
    position: 'fixed',
    maxWidth: '320px'
  },
  overlay: {
    backgroundColor: 'rgba(0)',
    zIndex: -1
  },
  root: {
    marginTop: '100px'
  }
};
