import axios from 'axios';

const apiUrl = 'https://api.monevert.com/';

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    Authorization: {
      toString () {
        return `Bearer ${localStorage.getItem('token')}`;
      }
    }
  }
});

export default instance;
