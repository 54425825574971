import jwtDecode from 'jwt-decode';
import * as actionTypes from './actionTypes';
import axios from '../../axios';
import { setCookie, time } from '../../shared/utility';

const saveToken = (token) => {
  const expirationDate = new Date(jwtDecode(token).exp * 1000); // bloody miliseconds
  const expiresIn = Math.round((expirationDate.getTime() - time().getTime()) / 1000);

  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', expirationDate);
  }
  setCookie('token', token, 720);
  setCookie('expirationDate', expirationDate, 720);

  return expiresIn;
};

export const authStart = () => ({
  type: actionTypes.AUTH_START
});

export const ajaxLoading = () => ({
  type: actionTypes.AUTH_AJAX_START
});

export const authSuccess = (token, user) => ({
  type: actionTypes.AUTH_SUCCESS,
  token,
  userId: jwtDecode(token).sub,
  role: jwtDecode(token).role,
  name: jwtDecode(token).name
});

export const passwordUpdated = token => ({
  type: actionTypes.AUTH_PASSWORD_UPDATED,
  token
});

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  error
});

export const ajaxFail = error => ({
  type: actionTypes.AUTH_AJAX_FAIL,
  error
});

export const recoveryEmailSent = email => ({
  type: actionTypes.AUTH_PASSWORD_RECOVERY_SENT,
  email
});

export const askForGeneratedCode = code => ({
  type: actionTypes.AUTH_TWO_FACTOR_AUTH_CODE_RECEIVED,
  code
});

export const auth = (email, password) => (dispatch) => {
  dispatch(authStart());

  axios.post('/auth/login', { email, password })
    .then((res) => {
      const token = res.data.token;
      if (token) {
        const expiresIn = saveToken(token);
        dispatch(authSuccess(token));
        dispatch(checkOffTimeout(expiresIn));
      } else {
        const twoFactorToken = res.data.two_factor_auth_token;
        dispatch(askForGeneratedCode(twoFactorToken));
      }
    })
    .catch((err) => {
      if (err.res) {
        dispatch(authFail(err.res.data.message));
      } else {
        dispatch(authFail('Other Error'));
      }
    });
};

export const auth2Fa = (code, twoFactorToken) => (dispatch) => {
  dispatch(authStart());

  axios.post('/auth/login2fa', {
    two_factor_code: code,
    two_factor_token: `${twoFactorToken}`
  })
    .then((res) => {
      const token = res.data.token;
      const expiresIn = saveToken(token);
      dispatch(authSuccess(token));
      dispatch(checkOffTimeout(expiresIn));
    })
    .catch((err) => {
      if (err.res) {
        dispatch(authFail(err.res.data.message));
      } else {
        dispatch(authFail('Other Error'));
      }
    });
};

export const authSocialLogin = (social, response) => (dispatch) => {
  dispatch(authStart());

  axios.post(`auth/${social}`, response)
    .then((res) => {
      const token = res.data.token;
      const expiresIn = saveToken(token);
      dispatch(authSuccess(token));
      dispatch(checkOffTimeout(expiresIn));
    })
    .catch((err) => {
      if (err.res) {
        dispatch(authFail(err.res.data.message));
      } else {
        dispatch(authFail('Connection Error'));
      }
    });
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkOffTimeout = expirationTime => (dispatch) => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime * 1000);
};

export const setAuthRedirectPath = path => ({
  type: actionTypes.SET_AUTH_REDIRECT_PATH,
  path
});

export const authCheckState = () => (dispatch) => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwtDecode(token);
    const now = new Date();
    if (now.getTime() < decoded.exp * 1000) {
      dispatch(authSuccess(token, null));

      const expirationDate = new Date(decoded.exp * 1000); // bloody miliseconds
      const expiresIn = Math.round((expirationDate.getTime() - now.getTime()) / 1000);
      dispatch(checkOffTimeout(expiresIn));
    } else {
      dispatch(authFail());
    }
  } else {
    dispatch(authFail());
  }
};

export const authPasswordUpdate = (oldToken, password) => (dispatch) => {
  axios.post('/auth/password', { password })
    .then((response) => {
      const token = response.data.token;
      const expiresIn = saveToken(token);
      dispatch(passwordUpdated(token));
      dispatch(checkOffTimeout(expiresIn));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(authFail(err.response.data.message));
      } else {
        dispatch(authFail('Connection Error'));
      }
    });
};

export const passwordRecovery = email => (dispatch) => {
  dispatch(ajaxLoading());
  axios.post('/auth/password-recovery', { email })
    .then((response) => {
      dispatch(recoveryEmailSent(email));
    })
    .catch((err) => {
      dispatch(ajaxFail(err.response.data.message));
    });
};

export const passwordRecoveryChange = (email, recoverToken, password) => {
  const data = {
    email,
    recoverToken,
    password
  };
  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.put('/auth/password-recovery', data)
      .then((response) => {
        const token = response.data.token;
        const expiresIn = saveToken(token);
        dispatch(authSuccess(token, null));
        dispatch(checkOffTimeout(expiresIn));
      })
      .catch((err) => {
        dispatch(ajaxFail(err.response.data.message));
      });
  };
};
